import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@organisms/layout'
import Heading from '@particles/heading'
import Text from '@particles/text'
import SEO from '@atoms/seo'
import { Link } from 'gatsby'
import { Container } from '@organisms/container'

const AboutPage = ({ location }) => (
  <Layout location={location}>
    <Container>
      <SEO title="About MetricHQ" location={location} />
      <Heading h1 margin="T4 B1.5">
        About MetricHQ
      </Heading>
      <Text size={1.25}>
        MetricHQ is an open library of metrics and KPIs, contributed by experts
        from all over the world.&nbsp;
        <Link href="/mission">
          Read more about our vision and mission here.
        </Link>
      </Text>
      <Text size={1.25} margin="T1">
        Metric definitions, calculations, benchmarks, and example use cases are
        added or updated on a regular basis, categorized by role, area, and by
        service. Metrics are also linked to similar metrics, and compared
        side-by-side, so that everyone can understand important differences
        between common terms such as Gross-Profit and Net-Profit.
      </Text>
      <Text size={1.25} margin="T1">
        As the largest directory of metrics and KPIs, MetricHQ is committed to
        providing verified, in-depth content, podcasts and advice about
        today&apos;s most important business metrics.
      </Text>
      <Heading h2 margin="T2.5 B1.5">
        Purpose
      </Heading>
      <Text size={1.25}>
        We believe that universally adopted open standards, including business
        metrics, foster collaboration, trust, and innovation among diverse
        stakeholders, including companies, academia, investors, regulatory
        bodies, and governments.
      </Text>
      <Heading h2 margin="T2.5 B1.5">
        Who is MetricHQ for?
      </Heading>
      <Text size={1.25}>
        Business users are the primary audience, and reference metric
        definitions and improve their data literacy. Domain experts and business
        analysts reference, debate, and validate content. Data and analytic
        engineers reference definitions and contribute to concepts, expressions,
        and best-practices.
      </Text>
      <Heading h2 margin="T2.5 B1.5">
        Who are we?
      </Heading>
      <Text size={1.25}>
        MetricHQ is governed by a group of dedicated stewards that will guide
        the growth and value of MetricHQ. Metric definitions, associations and
        related content are contributed by domain experts. MetricHQ was founded
        and is currently supported by Klipfolio.
      </Text>
      <Text size={1.25} margin="T1">
        Want to get involved? Help us create a platform that not only helps
        businesses make better decisions but also empowers them to become active
        participants in the evolution of metrics and KPIs. Standards and clear
        definitions will help everyone succeed in today&apos;s data-driven
        world.&nbsp;
        <Link href="/contribute/">Get in touch!</Link>
      </Text>
    </Container>
  </Layout>
)

AboutPage.propTypes = {
  location: PropTypes.any
}

export default AboutPage
